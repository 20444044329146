<script>
  export let searches;
  export let str = null;

  let parts;

  $: cleanSearches = searches ? searches.filter(s => s) : [];

  $: if (cleanSearches.length) {
    let regex = new RegExp(`(${cleanSearches.join("|")})`, "gi");
    parts = str ? str.split(regex) : "";
  } else {
    parts = null;
  }

  function isMatch(part) {
    for (let search of cleanSearches) {
      if (part.toLowerCase() === search.toLowerCase()) return true;
    }
    return false;
  }
</script>

<style>
  .highlight {
    background: #ff9;
    outline-width: 1px;
    outline-color: #ec9;
    outline-style: solid;
    color: #000;
  }
</style>

{#if parts}
  {#each parts as part}
    {#if isMatch(part)}
      <span class="highlight">{part}</span>
    {:else}{part}{/if}
  {/each}
{:else}{str}{/if}
