<script>
  import Papa from "papaparse";
  import Highlight from "./Highlight.svelte";

  let data;
  let tags;
  let eeps;
  let taxonomies;
  let types;
  let sources;
  let topics;
  let advisors;

  let selectedTag;
  let selectedTagEeps;
  let selectedEep;
  let selectedType;
  let selectedSource;
  let selectedTopic;
  let search;

  let filteredData;
  let resultCount;

  let tagsPerPage = 4;
  let currentPage = 1;
  let pageCount;

  let dataDiv;

  $: if (currentPage > pageCount) currentPage = pageCount;
  $: if (currentPage < 1 && pageCount) currentPage = 1;

  function nextPage() {
    currentPage++;
    dataDiv.scrollIntoView();
  }
  function prevPage() {
    currentPage--;
    dataDiv.scrollIntoView();
  }

  function notEmpty(value) {
    return value != null && value !== "";
  }

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  function asc(a, b) {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  }

  function uniqueValuesFromField(data, field) {
    return data
      .map((row) => row[field].trim())
      .filter(notEmpty)
      .filter(onlyUnique);
  }

  function uniqueValuesFromFieldWithLists(data, field) {
    let arr = [];
    for (let row of data) {
      row[field + "s"] = row[field].split(",").map((item) => item.trim());

      arr = arr.concat(row[field + "s"]);
    }
    return arr.filter(notEmpty).filter(onlyUnique);
  }

  let dataCsvPath =
    window.tag_eep_data_csv_path || "tags_eeps_data_2021-06-27.csv";
  let advisorsCsvPath =
    window.tag_eep_advisors_csv_path || "tags_vet_advisors_2021-06-27.csv";

  fetch(dataCsvPath)
    .then((res) => res.text())
    .then((res) => {
      data = Papa.parse(res, {
        header: true,
      }).data;
      tags = uniqueValuesFromField(data, "TAG");
      eeps = uniqueValuesFromField(data, "EEP");
      types = uniqueValuesFromField(data, "Type");
      sources = uniqueValuesFromField(data, "Source");
      taxonomies = uniqueValuesFromField(data, "Taxonomic Group");
      topics = uniqueValuesFromFieldWithLists(data, "Topic").sort(asc);
    });

  fetch(advisorsCsvPath)
    .then((res) => res.text())
    .then((res) => {
      res = res.replace(/^\W*?\n/);
      let data = Papa.parse(res, {
        header: true,
      }).data;

      advisors = [];

      for (let row of data) {
        let existing = advisors.find(
          (advisor) =>
            advisor.Email_Address === row.Email_Address &&
            advisor.TAG === row.TAG
        );
        if (existing) {
          existing.Programmes.push(row.Programme);
        } else if (row.Email_Address) {
          advisors.push({
            TAG: row.TAG,
            First_Name: row.First_Name,
            Last_Name: row.Last_Name,
            Email_Address: row.Email_Address,
            Programmes: [row.Programme],
          });
        }
      }

      advisors = advisors;
    });

  function getTAGAdvisors(tag) {
    let str = tag.trim();

    if (tag.includes("aterfowl")) str += "|waterfowl";
    if (tag.includes("ongbird")) str += "|passeriformes";
    if (tag.includes("hino")) str += "|rhino";
    if (tag.includes("ntelope")) str += "|antelope";
    if (tag === "Old World Monkey") str += "|afro-eurasian monkey";

    let regexp = new RegExp(str, "i");
    return advisors.filter((advisor) => {
      return regexp.test(advisor.TAG);
    });
  }

  $: if (data) {
    selectedTagEeps = data
      .filter((row) => row.TAG === selectedTag)
      .map((row) => row.EEP.trim())
      .sort((a, b) => {
        if (a < b) return -1;
        if (b > a) return 1;
        return 0;
      })
      .filter(onlyUnique);
  }

  $: searchBits = search ? search.split(/\s+?/) : [];

  $: if (data) {
    filteredData = {};
    resultCount = 0;
    let prevRow;
    let parentRow;

    for (let row of data) {
      if (selectedTag && row.TAG !== selectedTag) continue;
      if (selectedEep && row.EEP !== selectedEep) continue;
      if (selectedType && row.Type !== selectedType) continue;
      if (selectedSource && row.Source !== selectedSource) continue;
      if (selectedTopic && row.Topics.indexOf(selectedTopic) === -1) continue;

      if (search) {
        let searchBitsMatched = 0;
        for (let searchBit of searchBits) {
          let rgx = new RegExp(searchBit, "gi");
          if (
            row.TAG.match(rgx) ||
            row.EEP.match(rgx) ||
            row.Year.match(rgx) ||
            row["Taxonomic Group"].match(rgx) ||
            row.Title.match(rgx) ||
            row.Content.match(rgx) ||
            row.Type.match(rgx) ||
            row.Topic.match(rgx)
          )
            searchBitsMatched++;
        }

        if (searchBitsMatched !== searchBits.length) continue;
      }

      if (!filteredData[row.TAG]) filteredData[row.TAG] = {};
      if (!filteredData[row.TAG][row["Taxonomic Group"]])
        filteredData[row.TAG][row["Taxonomic Group"]] = {};
      if (!filteredData[row.TAG][row["Taxonomic Group"]][row.Source])
        filteredData[row.TAG][row["Taxonomic Group"]][row.Source] = [];

      let cloneRow = { ...row };

      if (row.Link && prevRow && prevRow.Link === row.Link) {
        if (!parentRow) {
          parentRow = prevRow;
          parentRow._continuation_items = 1;
        }
        parentRow._continuation_items++;
        cloneRow._continuation = true;
      } else {
        parentRow = undefined;
      }

      filteredData[row.TAG][row["Taxonomic Group"]][row.Source].push(cloneRow);
      resultCount++;
      prevRow = cloneRow;
    }

    filteredData = filteredData;
    pageCount = Math.ceil(Object.keys(filteredData).length / tagsPerPage);
  }

  function reset() {
    selectedEep = undefined;
  }

  function clearFilters() {
    selectedTag = undefined;
    selectedTagEeps = undefined;
    selectedEep = undefined;
    selectedTopic = undefined;
    selectedSource = undefined;
    selectedType = undefined;
  }

  $: activeFilterCount =
    (selectedTag ? 1 : 0) +
    (selectedEep ? 1 : 0) +
    (selectedTopic ? 1 : 0) +
    (selectedSource ? 1 : 0) +
    (selectedType ? 1 : 0);

  let showAdvisors = {};
</script>

<main>
  {#if data && advisors}
    <div bind:this={dataDiv}>
      <div class="search">
        <input
          type="text"
          bind:value={search}
          class="search-input"
          placeholder="🔎 Search documents..."
        />
        <div class="result-count">
          {resultCount} of {data.length} documents returned.
        </div>
      </div>
      <div class="filters">
        Filter:
        <select bind:value={selectedTag} on:change={reset}>
          <option value={undefined}>Select TAG...</option>
          {#each tags as tag}
            <option>{tag}</option>
          {/each}
        </select>
        <select disabled={!selectedTag} bind:value={selectedEep}>
          <option value={undefined}>Select EEP...</option>
          {#each selectedTagEeps as eep}
            <option>{eep}</option>
          {/each}
        </select>
        <select bind:value={selectedSource}>
          <option value={undefined}>Select Source...</option>
          {#each sources as source}
            <option>{source}</option>
          {/each}
        </select>
        <select bind:value={selectedType}>
          <option value={undefined}>Select Document Type...</option>
          {#each types as type}
            <option>{type}</option>
          {/each}
        </select>
        <select bind:value={selectedTopic}>
          <option value={undefined}>Select Topic...</option>
          {#each topics as topic}
            <option>{topic}</option>
          {/each}
        </select>
        {#if activeFilterCount}
          -
          <button on:click={clearFilters}>
            Clear filters ({activeFilterCount})
          </button>
        {/if}
      </div>
    </div>
    <hr />
    <div class="page-buttons">
      <button disabled={currentPage <= 1} on:click={prevPage}>&lt;</button>
      Page: {currentPage} / {pageCount}
      <button disabled={currentPage >= pageCount} on:click={nextPage}
        >&gt;</button
      >
    </div>
    <hr />
    {#each Object.entries(filteredData) as [tag, taxonomies], i}
      {#if tag && Math.ceil((i + 1) / tagsPerPage) === currentPage}
        <h2>
          <Highlight str={tag} searches={searchBits} /> TAG
          {#if !showAdvisors[tag]}
            <button
              on:click={() => {
                showAdvisors[tag] = true;
              }}>Show Vet Advisors</button
            >
          {:else}
            <button
              on:click={() => {
                showAdvisors[tag] = false;
              }}>Hide Vet Advisors</button
            >
          {/if}
        </h2>
        {#if getTAGAdvisors(tag) && showAdvisors[tag]}
          <ul class="advisors">
            {#each getTAGAdvisors(tag) as advisor}
              <li>
                {advisor.First_Name}
                {advisor.Last_Name}:
                <a href={"mailto:" + advisor.Email_Address}
                  >{advisor.Email_Address}</a
                >
                ({advisor.Programmes.join(", ")})
              </li>
            {/each}
          </ul>
        {/if}
        <table>
          <tr>
            <th class="eep">EEP</th>
            <th class="year">Year</th>
            <th class="title">Document Title</th>
            <th class="content">Document Content</th>
            <th class="page">Page</th>
            <th class="link">Link</th>
          </tr>
          {#each Object.entries(taxonomies) as [taxonomy, doctypes]}
            {#if Object.keys(taxonomies).length > -1}
              {#if taxonomy}
                <tr
                  ><td colspan="6" class="heading"
                    ><Highlight str={taxonomy} searches={searchBits} /></td
                  ></tr
                >
              {/if}
            {/if}
            {#each Object.entries(doctypes) as [doctype, docs]}
              <tr
                ><td
                  colspan="6"
                  class={Object.keys(taxonomies).length > -1
                    ? "subheading"
                    : "heading"}>{doctype}</td
                ></tr
              >
              {#each docs as doc}
                <tr>
                  {#if !doc._continuation}<td
                      class="eep"
                      rowspan={doc._continuation_items}
                      ><Highlight str={doc.EEP} searches={searchBits} /></td
                    >{/if}
                  {#if !doc._continuation}<td
                      class="year"
                      rowspan={doc._continuation_items}
                      ><Highlight str={doc.Year} searches={searchBits} /></td
                    >{/if}
                  {#if !doc._continuation}<td
                      class="title"
                      rowspan={doc._continuation_items}
                      ><Highlight str={doc.Title} searches={searchBits} /></td
                    >{/if}
                  <td class="content">
                    {#if doc.Content}
                      {#each doc.Content.split("\n") as line, i}
                        {#if i}<br />{/if}
                        <Highlight str={line} searches={searchBits} />
                      {/each}
                    {/if}
                  </td>
                  <td class="page">{doc.Page}</td>
                  {#if !doc._continuation}<td
                      class="nowrap link"
                      rowspan={doc._continuation_items}
                      >{#if doc.Link}<a href={doc.Link} target="_blank"
                          >Access here</a
                        >{/if}</td
                    >{/if}
                </tr>
              {/each}
            {/each}
          {/each}
        </table>
      {/if}
    {/each}
    <hr />
    <div class="page-buttons">
      <button disabled={currentPage <= 1} on:click={prevPage}>&lt;</button>
      Page: {currentPage} / {pageCount}
      <button disabled={currentPage >= pageCount} on:click={nextPage}
        >&gt;</button
      >
    </div>
  {:else}
    Loading...
  {/if}
</main>

<style>
  :global(table) {
    border-collapse: collapse;
  }

  select {
    display: inline-block;
    width: 100px;
  }

  input {
    display: block;
  }
  td {
    border: 1px solid grey;
    padding: 0.25em;
  }

  .heading {
    background: rgb(42 137 42);
    color: white;
  }

  .subheading {
    background: rgb(138, 184, 138);
    color: white;
  }

  .nowrap {
    white-space: nowrap;
  }

  .advisors {
    background: palegoldenrod;
    border: 1px solid goldenrod;
    padding: 0.5em 2em;
    list-style-type: circle;
    margin: 1em 0;
  }

  .search {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
  }

  .search-input {
    font-size: 1em;
    padding: 0.5em;
    border-radius: 0.5em;
    border: 1px solid green;
    min-width: 500px;
  }

  .result-count {
    margin-left: 0.5em;
  }

  .filters select {
    font-size: 1em;
    padding: 0.2em;
  }

  table {
    margin-bottom: 2em;
  }

  th {
    background: rgb(42 137 42);
    color: white;
    text-align: left;
    font-weight: normal;
    font-style: italic;
    border: 1px solid rgb(79 155 79);
    padding: 0.25em;
  }

  td.year,
  td.link {
    width: 5%;
  }
  td.eep {
    width: 15%;
  }
  td.content {
  }
  td.title {
    width: 30%;
  }
  td.page {
    width: 8%;
  }

  .page-buttons {
    text-align: center;
  }
</style>
